import { create } from "zustand";
import { createInitializedContext } from "../context";
import { useQuery } from "@tanstack/react-query";

type Role = "ADMIN" | "CUSTOMER" | "VIEWER" | "STAKEHOLDER" | "KEYHOLDER";

interface AuthStore {
  token: string | null;
  actions: {
    setToken: (token: string) => void;
  };
}

export const authStoreV2 = create<AuthStore>((set) => ({
  token: null,
  actions: {
    setToken: (token: string) => {
      set(() => ({ token }));
    },
  },
}));

export const getAuthToken = () => authStoreV2.getState().token;

export const { use: useAuth, provider: AuthProvider } =
  createInitializedContext("AuthContext", () => {
    const info = useGetUserInfo();

    const result = {
      get ready() {
        return info.isSuccess;
      },
      get session() {
        return info.data;
      },
      get token() {
        return info.data?.token;
      },
      get type() {
        return info.data?.type;
      },
      get logout() {
        return async () => {
          authStoreV2.setState({ token: null });
          try {
            await fetch("/api/auth/logout");

            if (process.env.NEXT_PUBLIC_APP_MODE === "dev") {
              location.href = "/";
            } else {
              location.href = "https://www.anchorwatch.com";
            }
          } catch (error) {
            console.error(error);
          }
        };
      },
    };
    return result;
  });

function useGetUserInfo() {
  return useQuery({
    queryKey: ["user-info"],
    queryFn: getUserInfo,
    refetchInterval: 1000 * 60,
  });
}

type SessionInfo =
  | {
      token: string;
      properties: {
        email: string;
        userID: string;
        role: Role;
      };
      type: "user";
    }
  | {
      token: string;
      properties: {
        email: string;
        userID: string;
      };
      type: "mfa" | "mfa_setup";
    };

const getUserInfo = async () => {
  try {
    const response = await fetch("/api/auth/session");
    if (response.status === 204) return null;
    const data = (await response.json()) as SessionInfo;
    if (!data) return null;
    if (!data.token) return null;
    authStoreV2.getState().actions.setToken(data.token);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
