import { loggerLink, unstable_httpBatchStreamLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import superjson from "superjson";
import type { AppRouter } from "@trident/functions/trpc/root";
import { getAuthToken } from "@/features/providers/auth2";

/** A set of type-safe react-query hooks for the tRPC API. */
export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      /**
       * Links used to determine request flow from client to server.
       *
       * @see https://trpc.io/docs/links
       */
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === "development" ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        unstable_httpBatchStreamLink({
          transformer: superjson,
          url: `${process.env.NEXT_PUBLIC_API_URL}`,
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
          headers: () => {
            const token = getAuthToken();
            return {
              Authorization: `Bearer ${token}`,
              "x-trpc-source": "nextjs-react",
            };
          },
        }),
        // splitLink({
        //   condition(op) {
        //     // check for context property `skipBatch`
        //     return Boolean(op.context.skipBatch);
        //   },
        //   // when condition is true, use normal request
        //   true: httpLink({
        //     url: `${process.env.NEXT_PUBLIC_API_URL}`,
        //     fetch(url, options) {
        //       const token = getAuthToken();
        //       return fetch(url, {
        //         ...options,
        //         credentials: "include",
        //         headers: {
        //           Authorization: `Bearer ${token}`,
        //         },
        //       });
        //     },
        //     transformer: superjson,
        //   }),
        //   // when condition is false, use batching
        //   false: httpBatchLink({
        //     url: `${process.env.NEXT_PUBLIC_API_URL}`,
        //     fetch(url, options) {
        //       const token = getAuthToken();
        //       return fetch(url, {
        //         ...options,
        //         credentials: "include",
        //         headers: {
        //           Authorization: `Bearer ${token}`,
        //         },
        //       });
        //     },
        //     transformer: superjson,
        //   }),
        // }),
      ],
    };
  },

  /**
   * Whether tRPC should await queries when server rendering pages.
   *
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
  transformer: superjson,
});

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;
