import { useAuth } from "./auth2";
import { useEffect } from "react";
import { api } from "@/utils/api";
import { usePathname } from "next/navigation";
import mqtt from "mqtt";
import { debounce } from "remeda";

function createConnection(token: string) {
  const endpoint = process.env.NEXT_PUBLIC_IOT_HOST;
  const authorizer = process.env.NEXT_PUBLIC_IOT_AUTHORIZER;

  return mqtt.connect(
    `wss://${endpoint}/mqtt?x-amz-customauthorizer-name=${authorizer}`,
    {
      protocolVersion: 5,
      manualConnect: true,
      username: "", // Must be empty for the authorizer
      password: token, // Passed as the token to the authorizer
      clientId: `client_${window.crypto.randomUUID()}`,
    },
  );
}

export function RealtimeProvider() {
  const pathname = usePathname();
  const utils = api.useUtils();
  const { session, token } = useAuth();

  useEffect(() => {
    if (!session || !token || (!!pathname && pathname.includes("/auth/"))) {
      return;
    }

    const topic = `${process.env.NEXT_PUBLIC_NAME}/${process.env.NEXT_PUBLIC_STAGE}/${session.properties.userID}/all/#`;
    const connection = createConnection(token);

    connection.on("connect", async () => {
      try {
        await connection.subscribeAsync(topic, { qos: 1 });
        console.log("WS connected");
      } catch (e) {
        if (e instanceof Error) {
          if (e.message.includes("client disconnecting")) {
            console.log("double mount react stuff, just ignore");
          } else {
            console.log(e);
          }
        } else {
          console.log(e);
        }
      }
    });

    connection.on("error", (e) => {
      if (e.name?.includes("client disconnecting")) {
        console.log("double mount react stuff, just ignore");
        return;
      } else {
        console.log("connection error", e, e.name, e.cause, e.message, e.stack);
      }
    });

    connection.on("message", (fullTopic, payload) => {
      debouncedHandleMessage.call(fullTopic, payload, utils);
    });

    connection.on("disconnect", console.log);

    void connection.connect();

    return () => {
      void connection.end();
    };
  }, [session, session?.properties.userID, token, utils, pathname]);

  return null;
}

function handleMessage(
  fullTopic: string,
  payload: ArrayBuffer,
  utils: ReturnType<typeof api.useUtils>,
) {
  const splits = fullTopic.split("/");
  const topic = splits[4];
  if (!topic) return;
  const message = new TextDecoder("utf8").decode(new Uint8Array(payload));
  const parsed = JSON.parse(message) as {
    properties: Record<string, unknown>;
  };

  console.log(fullTopic);

  switch (topic) {
    case "poke": {
      console.log("got poke", parsed);
      break;
    }

    case "alerts": {
      console.log("got alerts", parsed);
      void utils.alert.get_alerts.invalidate();
      void utils.alert.get_badge_count.invalidate();
      break;
    }

    case "REFETCH_WALLETS": {
      console.log("got REFETCH_WALLETS", parsed);
      void utils.wallet.get_all_user_wallets.invalidate();
      break;
    }

    case "REFETCH_UNVERIFIED": {
      void utils.wallet.get_unverified_wallet_by_id.invalidate({
        id: (parsed.properties as { wallet_id: string }).wallet_id,
      });
      break;
    }

    case "DEPOSIT_ALERT": {
      const { walletID } = parsed.properties as { walletID: string };
      void utils.alert.get_alerts.invalidate();
      void utils.alert.get_badge_count.invalidate();
      void utils.transactions.get_wallet_transactions_and_psbts.invalidate({
        wallet_id: walletID,
      });
      void utils.transactions.get_wallet_chart_data.invalidate({
        wallet_id: walletID,
      });
      void utils.balance.get_wallet_balance_by_id.invalidate({
        wallet_table_id: walletID,
      });
      break;
    }

    case "NEW_FUNDS": {
      console.log("got NEW_FUNDS", parsed);
      const walletId = (parsed.properties as { wallet_id: string }).wallet_id;
      if (!walletId) return;
      void utils.balance.get_wallet_balance_by_id.invalidate({
        wallet_table_id: (parsed.properties as { wallet_id: string }).wallet_id,
      });
      void utils.transactions.get_wallet_transactions_and_psbts.invalidate({
        wallet_id: walletId,
      });
      break;
    }

    case "update_key_health_type": {
      console.log("got update_key_health_type", parsed);
      const key_id = (parsed.properties as { key_id: string }).key_id;
      void utils.key.get_key_details_by_id.setData({ id: key_id }, (data) => {
        if (!data) return;
        return {
          ...data,
          info: {
            ...data.info,
            key_health: "HEALTHY",
          },
        };
      });
      break;
    }

    case "GET_ADD_KEY_STATUS": {
      console.log("got GET_ADD_KEY_STATUS", parsed);
      const addKeyWalletId = (parsed.properties as { wallet_id: string })
        .wallet_id;
      if (!addKeyWalletId) return;
      void utils.wallet.get_user_add_key_to_wallet_status.invalidate({
        wallet_id: addKeyWalletId,
      });
      break;
    }

    case "WALLET_CREATED": {
      console.log("got WALLET_CREATED", parsed);
      const walletInfo = parsed.properties as {
        wallet_id: string;
        is_owner: boolean;
      } | null;
      if (!walletInfo) return;
      const { wallet_id: createdWalletId, is_owner } = walletInfo;
      if (!createdWalletId) return;
      if (is_owner) {
        void utils.wallet.get_all_user_wallets.invalidate();
      } else {
        void utils.wallet.get_wallet_user_has_keys_in.invalidate();
      }
      void utils.wallet.has_wallet_been_created.invalidate({
        wallet_id: createdWalletId,
      });
      break;
    }

    case "REFETCH_REGISTER_KEYS": {
      console.log("got REFETCH_REGISTER_KEYS", parsed);
      const registerWalletInfo = parsed.properties as {
        wallet_id: string;
        is_wallet_owner: boolean;
      } | null;
      if (!registerWalletInfo) return;
      const { wallet_id: registerWalletId, is_wallet_owner: isWalletOwner } =
        registerWalletInfo;
      if (!registerWalletId) return;
      void utils.wallet.get_unverified_wallet_by_id.invalidate({
        id: registerWalletId,
      });
      if (isWalletOwner) {
        const allWallets = utils.wallet.get_all_user_wallets.getData();
        if (allWallets) {
          const foundWallet = allWallets.find((w) => w.id === registerWalletId);
          if (foundWallet) {
            utils.wallet.get_all_user_wallets.setData(undefined, (prev) => {
              return prev?.map((w) => {
                if (w.id === registerWalletId) {
                  return {
                    ...w,
                    total_registered_keys: w.total_registered_keys + 1,
                  };
                }
                return w;
              });
            });
          }
        }
        void utils.wallet.get_all_user_wallets.invalidate();
      } else {
        const userWallets = utils.wallet.get_wallet_user_has_keys_in.getData();
        if (userWallets) {
          const foundWallet = userWallets.find(
            (w) => w.id === registerWalletId,
          );
          if (foundWallet) {
            utils.wallet.get_wallet_user_has_keys_in.setData(
              undefined,
              (prev) => {
                return prev?.map((w) => {
                  if (w.id === registerWalletId) {
                    return {
                      ...w,
                      total_registered_keys: w.total_registered_keys + 1,
                    };
                  }
                  return w;
                });
              },
            );
          }
        }
        void utils.wallet.get_wallet_user_has_keys_in.invalidate();
      }
      break;
    }

    case "viewer_permission_change":
    case "permission_change": {
      console.log("got permission_change", parsed);
      void utils.alert.get_alerts.invalidate();
      void utils.alert.get_badge_count.invalidate();
      void utils.wallet.get_wallet_permissions.invalidate();
      break;
    }

    case "broadcasted_tx": {
      console.log("got broadcasted_tx", parsed);
      void utils.alert.get_alerts.invalidate();
      void utils.alert.get_badge_count.invalidate();
      const { tx_id: txID, wallet_id: walletID } = parsed.properties as {
        tx_id: string;
        wallet_id: string;
      };
      void utils.transactions.get_tx_receipt_v2.invalidate({
        tx_id: txID,
        wallet_id: walletID,
      });
      break;
    }

    case "refetch_viewer_wallets": {
      console.log("got refetch_viewer_wallets", parsed);
      void utils.alert.get_alerts.invalidate();
      void utils.alert.get_badge_count.invalidate();
      void utils.wallet.viewer_wallets.invalidate();
      break;
    }

    case "refetch_psbt_receipt": {
      console.log("got refetch_psbt_receipt", parsed);
      void utils.alert.get_alerts.invalidate();
      void utils.alert.get_badge_count.invalidate();
      const { tx_id, wallet_id } = parsed.properties as {
        tx_id: string;
        wallet_id: string;
      };
      void utils.transactions.get_tx_receipt_v2.invalidate({
        tx_id,
        wallet_id,
      });
      break;
    }

    case "checkout.session.async_payment_succeeded":
    case "checkout.session.completed": {
      const sessionID = parsed.properties.sessionID as string;
      console.log("got checkout.session.async_payment_succeeded", parsed);
      void utils.payment.status.invalidate({ sessionID });
      break;
    }

    case "refetch.wallet_name": {
      const walletID = parsed.properties.walletID as string;
      console.log("got refetch.wallet_name", parsed);
      void utils.wallet.get_wallet_name_by_id.invalidate({
        wallet_table_id: walletID,
      });
      void utils.wallet.get_all_user_wallets.invalidate();
      break;
    }

    default:
      console.log("message not handled");
      console.log("topic", topic);
      console.log("got message", parsed);
      break;
  }
}

const debouncedHandleMessage = debounce(handleMessage, {
  timing: "trailing", // Wait until the end of the cool-down period
  waitMs: 1000, // 1 second cool-down period
});
