import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { Toaster } from "@trident/ui/toast";
import { DM_Mono, DM_Sans } from "next/font/google";
import { AuthProvider } from "@/features/providers/auth2";
import { RealtimeProvider } from "@/features/providers/realtime";
import { api } from "@/utils/api";

const dmSans = DM_Sans({
  variable: "--font-sans",
  subsets: ["latin-ext"],
  display: "swap",
});

const dmMono = DM_Mono({
  variable: "--font-mono",
  weight: ["300", "400", "500"],
  subsets: ["latin"],
  display: "swap",
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <AuthProvider>
      <RealtimeProvider />
      <style jsx global>{`
        :root {
          --font-mono: ${dmMono.variable};
          --font-sans: ${dmSans.variable};
        }
      `}</style>
      <main className={`font-sans ${dmSans.variable} ${dmMono.variable}`}>
        <Component {...pageProps} />
        <Toaster />
      </main>
    </AuthProvider>
  );
};

export default api.withTRPC(MyApp);
