import type { ReactNode} from "react";
import { createContext, useContext } from "react";

export function createInitializedContext<
  Name extends string,
  T extends { ready: boolean },
>(name: Name, cb: () => T) {
  const ctx = createContext<T | null>(null);

  return {
    use: () => {
      const context = useContext(ctx);
      if (!context) throw new Error(`No ${name} context`);
      return context;
    },

    provider: (props: { children: ReactNode }) => {
      const value = cb();
      if (!value.ready) {
        return null;
      } else {
        return <ctx.Provider value={value}>{props.children}</ctx.Provider>;
      }
    },
  };
}
